import styles from "./categories.component.less";
import PageContent from "../../pageContent/pageContent";
import Caption from "../../caption/caption";
import { NavLink } from "react-router-dom";

export default function Categories(props) {
    let cats = props.categories.map(x => <NavLink key={x.id} to={"/categories/" + x.id} className={styles.line}>{x.name}</NavLink>);
    return (
        <PageContent>
            <Caption title="Категории" />
            {cats}
        </PageContent>
    )
}