import api from "../../api/productsApi";

const ACTION_SET = "store/products/set";
const ACTION_CHANGE_PRODUCT_DETAILS = "store/products/change-details";

let initialState = {
    data: {}
}


export default function ProductsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET: {
            return {
                ...state,
                data: {
                    ...state.data, [action.data.catId]: action.data.products
                }
            }
        };
        case ACTION_CHANGE_PRODUCT_DETAILS: {
            let updated = {
                ...state,
                data: {
                    ...state.data,
                    [action.data.catId]: state.data[action.data.catId].map(
                        (content, i) => content.id === action.data.id ? { ...content, price: action.data.price }
                            : content
                    )
                }
            };
            return updated;
        };
        default: return state;
    }
}

const ActionSave = (data) => ({
    type: ACTION_SET,
    data: data
});

export function ThunkCretorGetProducts(catId) {
    return (dispatch) => {
        api.get(catId).then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSave(operResult.value));
            }
        });
    }
}

export const ActionChangeProductDetails = (data) => ({
    type: ACTION_CHANGE_PRODUCT_DETAILS,
    data: data
});