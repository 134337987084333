import apiConf from "./apiConfiguration";
import net from "./net";

const productsApi = {
    get(catId) {
        return net.get(apiConf.endpoint("/products/getproduct/" + catId)).then((response) => response);
    },
    getProductDetails(id) {
        return net.get(apiConf.endpoint("/products/getdetails/" + id)).then((response) => response);
    },
    save(model) {
        return net.post(apiConf.endpoint("/products/saveprod"), model, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then((response) => response);
    },
};

export default productsApi;