import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Products from "./products";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetProducts } from "../../../context/reducers/productsReducer";
import PrivateRoute from "../private/privateRoute";


function ProductsContainer(props) {
    let catId = props.router.params.id;
    if (catId) {
        if (!props.productsByCategory[catId]) props.get(props.router.params.id);
        if (props.productsByCategory[catId])
            return <Products products={props.productsByCategory[catId]} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        productsByCategory: state.products.data,
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: (catId) => {
        dispatch(ThunkCretorGetProducts(catId));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(ProductsContainer);