import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { OrderDetails } from "./orders";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetOrders, ThunkCretorChangeOrderStatus } from "../../../context/reducers/ordersReducer";
import PrivateRoute from "../private/privateRoute";


function OrderDetailsContainer(props) {
    let [selected, changeSelected] = useState(null);
    let [statusPopup, changeStatusPopup] = useState(false);

    if (!props.orders || !props.orders.length) {
        props.getOrders();
    }

    if (selected == null) {
        let order = props.orders.filter(x => {
            if (x.id == props.router.params.id)
                return x;
        });
        if (order.length)
            changeSelected(order[0]);
    }

    let showPopup = () => changeStatusPopup(true);
    let closePopup = () => changeStatusPopup(false);


    let changeOrderStatus = (event, orderId, statusId) => {
        event.stopPropagation();
        props.changeOrderStatus(orderId, statusId);
        closePopup();
    }


    if (!selected) return;

    return <OrderDetails order={selected} statusPopup={statusPopup} showPopup={showPopup} closePopup={closePopup} changeOrderStatus={changeOrderStatus} />
}


const mapStateToProps = (state, ownProps) => {
    return {
        orders: state.orders.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOrders: () => {
        dispatch(ThunkCretorGetOrders());
    },
    changeOrderStatus: (orderId, statusId) => {
        dispatch(ThunkCretorChangeOrderStatus(orderId, statusId));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(OrderDetailsContainer);
