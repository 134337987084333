import styles from "./products.component.less";
import ProductLine from "./productLine";
import PageContent from "../../pageContent/pageContent";

export default function Products(props) {
    if (!props) return;
    let prods = props.products.map(x => <ProductLine key={x.id} {...x}></ProductLine>);
    return (
        <PageContent>
            {prods}
        </PageContent>
    )
}