import React from "react";
import styles from "./menuItem.component.less";
import { NavLink } from "react-router-dom";
import commonStyles from "../shared/common.component.less"

const MenuItem = (props) => {
    return (
        <NavLink className={styles.component + " " + (props.disabled ? commonStyles.disabled : "")} to={props.uri}>
            <i className={`icon-${props.iconName}`} />
            <h4>
                {props.name}
            </h4>
        </NavLink>
    );
}

export default MenuItem;