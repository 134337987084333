import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Categories from "./categories";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetCategories } from "../../../context/reducers/categoriesReducer";
import PrivateRoute from "../private/privateRoute";


function CategoriesContainer(props) {
    if (!props.categories) props.getCategories();
    if (props.categories) return <Categories categories={props.categories} />
}

const mapStateToProps = (state) => {
    return {
        //products: state.products.data,
        categories: state.categories.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    //get: () => {
    //    dispatch(ThunkCretorGetProducts());
    //},
    getCategories: () => {
        dispatch(ThunkCretorGetCategories());
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(CategoriesContainer);