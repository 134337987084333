import api from "../../api/categoriesApi";

const ACTION_SET = "store/categories/set";

let initialState = {
    data: null
}


export default function CategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET: {
            return {
                ...state,
                data: action.data
            }
        }
        default: return state;
    }
}

const ActionSave = (data) => ({
    type: ACTION_SET,
    data: data
});

export function ThunkCretorGetCategories() {
    return (dispatch) => {
        api.get().then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSave(operResult.value));
            }
        });
    }
}