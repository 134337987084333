import styles from "./productLine.component.less"
import { NavLink } from "react-router-dom";

export default function ProductLine({id, name, price, imageUri }) {
    return (
        <NavLink to={"/products/" + id} className={styles.component}>
            <div className={styles.image}><img src={imageUri} width="80" height="80" /></div>
            <div className={styles.productInfo}>
                <div className={styles.productName}>
                    {name}
                </div>
                <hr />
                <div>
                    {price} руб
                </div>
            </div>
        </NavLink>
    );
}