import React, { useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import styles from "./login.component.less";
import { NavLink } from "react-router-dom";
import logo from "../../../images/oms-logo.jpg";

export default function Login(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    //return <LoginRxForm initialValues={({ login: 'present_pizza_manager', password:'present7458pizza'})} {...props} onSubmit={props.onSubmit} />
    return <LoginRxForm {...props} onSubmit={props.onSubmit} />
}

let LoginRxForm = reduxForm({ form: 'login' })(Form);

function Form(props) {
    return (
        <div className={styles.component}>
            <div>
                <img src={logo} width="150" />
                <form onSubmit={props.handleSubmit}>
                    <div>
                        <Field placeholder="Имя пользователя" component={"input"} name={"login"} type={"text"} autoComplete="off" />
                    </div>
                    <div className={styles.passContainer}>
                        <Field placeholder="Пароль" component={"input"} name={"password"} type={props.showPass ? "text" : "password"} autoComplete="off" />
                        <i className={"icon-eye"} onClick={(e) => props.showHidePassword(e)}></i>
                    </div>
                    <button>войти</button>
                </form>
            </div>
        </div>
    )
}