import ordersApi from "../../api/ordersApi";
import { toast } from 'react-toastify';

const ACTION_SET_ORDERS = "store/orders/set";
const ACTION_ADD_NEW_ORDERS = "store/orders/add/new";
const ACTION_SET_EXTRA_ORDER_DATA = "store/orders/set/extra";

let initialState = {
    data: [],
    extraData: {
        lastOrderDate: null
    }
}

export default function OrdersReducer(state = initialState, action)
{
    switch (action.type) {
        case ACTION_SET_ORDERS: {
            return { ...state, data: action.data }
        }
        case ACTION_ADD_NEW_ORDERS: {
            return { ...state, data: [...state.data, ...action.data] }
        }
        case ACTION_SET_EXTRA_ORDER_DATA: {
            return { ...state, extraData: action.data }
        }
        default: return state;
    }
}

const ActionAddNewOrders = (data) => ({
    type: ACTION_ADD_NEW_ORDERS,
    data: data
});

const ActionSetOrders = (data) => ({
    type: ACTION_SET_ORDERS,
    data: data
});

const ActionSetExtraData = (data) => ({
    type: ACTION_SET_EXTRA_ORDER_DATA,
    data: data
});

export const ActionSelectOrder = (order) => ({
    type: ACTION_SET_ORDERS,
    data: order
});

export function ThunkCretorChangeOrderStatus(orderId, newStatusId) {
    return (dispatch) => {
        ordersApi.changeOrderStatus(orderId, newStatusId).then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSetOrders(operResult.value));
                toast.success("Статус успешно изменён!");
            }
        });
    }
}

export function ThunkCretorGetOrders() {
    return (dispatch) => {
        ordersApi.get().then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSetOrders(operResult.value));
                dispatch(ActionSetExtraData(JSON.parse(operResult.jsonData)));
            }
        });
    }
}

export function ThunkCretorCheck4NewOrders(lastOrderDate) {
    return (dispatch) => {
        ordersApi.check4NewOrders(lastOrderDate).then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                if (operResult.value.length) {
                    dispatch(ActionAddNewOrders(operResult.value));
                    dispatch(ActionSetExtraData(JSON.parse(operResult.jsonData)));
                }
            }
        });
    }
}