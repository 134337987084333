import apiConf from "./apiConfiguration";
import net from "./net";

const authApi = {
    async get() {
        return await net.get(apiConf.endpoint("/auth/isauthenticated")).then((response) => response);
    },
    login(model) {
        return net.post(apiConf.endpoint("/auth/login"), model, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response);
    },
    logout() {
        return net.post(apiConf.endpoint("/auth/logout")).then((response) => response);
    }
};

export default authApi;