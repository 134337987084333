import styles from "./stats.component.less";
import PageContent from "../../pageContent/pageContent";
import Caption from "../../caption/caption";

export default function Stats({ appUserCount, turnonverMonth, turnonverYear, turnonverAllTime }) {
    return (
        <PageContent>
            <Caption title="Статистика" />
            <div className={styles.line}>
                Кол-во пользователей: <b>{appUserCount}</b>
            </div>
            <fieldset className={styles.fieldset}>
                <legend>
                    Оборот за
                </legend>

                <div className={styles.line}>
                    месяц: <b>{turnonverMonth}</b>
                </div>
                <div className={styles.line}>
                    весь год: <b>{turnonverYear.toFixed(2)} руб</b>
                </div>
                <div className={styles.line}>
                    все время: <b>{turnonverAllTime.toFixed(2)} руб</b>
                </div>
            </fieldset>
           
        </PageContent>
    )
}